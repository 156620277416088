<template>
  <nav
    :class="classes"
    class="tn--nav fixed top-0 z-40 flex h-[52px] w-full flex-row justify-between px-gap2 transition-all delay-100 md:h-[75px]"
  >
    <ul class="flex h-full w-full flex-row items-center justify-between">
      <li class="h-[31px] w-[100px] md:w-[126px]">
        <NuxtLink :to="localePath('/')">
          <LogosSvgLogoMain
            class="tn-logo h-full w-full transition-all"
            :fill="
              !isTransparent ||
              scrollDirection === NavigationDirection.UP ||
              navigationActive
                ? '#00263e'
                : 'white'
            "
          />
        </NuxtLink>
      </li>
      <li
        class="tn--nav-buttons relative flex h-[22px] flex-row items-center justify-center"
      >
        <template v-if="navButtons.length > 2">
          <template
            v-for="button in navButtons.slice(0, 1)"
            :key="button.__typename"
          >
            <ButtonsTheButton
              v-if="button.__typename === 'Button'"
              :button="buttonProps(button)"
            />
          </template>
        </template>
        <button
          class="tn--nav-toggle z-60 w-full pl-1/2"
          @click="toggleMobileMenu"
        >
          <IconsSvgMobileMenu
            v-show="!isMobileActive"
            :stroke="
              scrollDirection === NavigationDirection.UP || !isTransparent
                ? '#00263E'
                : 'white'
            "
            class="w-full"
          />
          <IconsSvgCloseIcon
            v-show="isMobileActive"
            class="z-20"
            :stroke="isMobileActive ? '#00263E' : 'white'"
          />
        </button>
      </li>
    </ul>
  </nav>
  <nav
    v-if="isMobileActive"
    :style="{ height: `${mobileHeight}px` }"
    class="tn--nav-mobile fixed right-0 top-0 z-50 w-[calc(100%-20px)] bg-white md:w-[calc(100%-83px)]"
  >
    <div class="relative h-[52px] md:h-[75px]">
      <button
        tabindex="0"
        class="tn--nav-toggle absolute right-[16px] top-[50%] z-[60] h-[22px] w-[20px] translate-y-[-50%]"
        @click.prevent="toggleMobileMenu"
      >
        <IconsSvgCloseIcon
          class="h-full w-full"
          :stroke="isMobileActive ? '#00263E' : 'white'"
        />
      </button>
    </div>

    <div
      class="relative z-10 h-[calc(100%-127px)] w-full md:h-[calc(100%-150px)]"
    >
      <ul class="items-left flex h-full flex-col overflow-y-auto">
        <li
          v-for="(parentLink, parentIndex) in links"
          :key="parentIndex"
          :role="parentLink.__typename === 'NavLinks' ? 'button' : ''"
          :aria-current="linkStates[parentIndex].active ? true : false"
          :class="{
            'border-none ': linkStates[parentIndex].active,
          }"
          class="pb-[24px]"
        >
          <div
            v-if="parentLink.__typename === 'NavLinks'"
            :class="{
              'pb-[24px] text-blue': linkStates[parentIndex].active === true,
            }"
            class="sticky top-0 z-10 flex flex-row items-center justify-between bg-white px-1 text-[20px] font-medium leading-[30px] transition-all"
            @click="setActiveMenuItem(parentIndex)"
          >
            {{ parentLink.text }}
            <IconsSvgChevron
              class="transition-all"
              :stroke="
                linkStates[parentIndex].active === true ? '#0384FB' : '#00263E'
              "
              :class="{
                '-rotate-90': linkStates[parentIndex].active === true,
                'rotate-90': linkStates[parentIndex].active !== true,
              }"
            />
            <div
              :class="{ hidden: linkStates[parentIndex].active !== true }"
              class="absolute bottom-0 left-[50%] h-[1px] w-[90%] translate-x-[-50%] bg-[#00263E]"
            ></div>
          </div>
          <ul
            v-if="
              parentLink.__typename === 'NavLinks' &&
              parentLink.groupsCollection
            "
            :class="{
              'max-h-full': linkStates[parentIndex].active === true,
              'max-h-0 overflow-hidden':
                linkStates[parentIndex].active === false,
            }"
            class="transition-all"
          >
            <li
              v-for="groupItem in parentLink.groupsCollection.items"
              :key="groupItem"
              class="relative pt-[24px] last:pb-[24px]"
            >
              <ul>
                <li
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  :key="childLink"
                  class="tn--nav-animateY opacity-0"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'text-xs font-bold uppercase ':
                        childLink?.type === 'Header',
                      'pb-0':
                        childIndex ===
                        groupItem.linksCollection.items.length - 1,
                      'py-1/2':
                        childIndex < groupItem.linksCollection.items.length,
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    class="tn--link justify-left mb-0 flex h-full flex-col items-center px-2 focus:bg-blue-lighter active:bg-blue-lighter"
                    @click="trackLink(childLink.link, childLink.text)"
                  >
                    <div
                      class="relative h-full w-full"
                      :class="{
                        'border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-[#D6DBE1] pb-[24px]':
                          childIndex ===
                          groupItem.linksCollection.items.length - 1,
                      }"
                    >
                      <div class="justify-left flex flex-row items-center">
                        <span
                          :class="{
                            'tn--link-header font-bold tracking-[1px]':
                              childLink?.type === 'Header',
                            'tn--link font-medium':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="text-blue-dark focus:text-blue"
                        >
                          {{ childLink.text }}
                        </span>
                      </div>
                      <span
                        v-if="childLink.previewText"
                        class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                      >
                        {{ childLink.previewText }}
                      </span>
                    </div>
                  </NuxtLink>
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link mb-0 block w-full px-2 pb-1/2 text-xs font-bold uppercase tracking-[1px] focus:text-blue-dark active:text-blue-dark"
                  >
                    {{ childLink.text }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <LinksLocaleLink
            v-if="parentLink.__typename === 'NavLink'"
            class="mb-0 px-1 pb-0 text-[20px] font-medium leading-[30px]"
            :link="parentLink.link"
            :target="parentLink.newWindow ? '_blank' : '_self'"
            @click="trackLink(parentLink.link, parentLink.text)"
            >{{
              parentLink.text ? parentLink.text : 'Nav Link'
            }}</LinksLocaleLink
          >
        </li>
        <li>
          <ButtonsTheLocaleSelector
            class="justify-left flex flex-row border border-b-0 border-l-0 border-r-0 border-t-1 px-1 py-1"
            :transparent="false"
            :countries="countries"
          />
        </li>
      </ul>
    </div>
    <ul
      class="tn--buttons-mobile absolute bottom-0 z-20 flex h-[75px] w-full items-center justify-evenly border border-b-0 border-l-0 border-r-0 border-t-1 border-solid bg-white px-space2"
    >
      <li
        v-for="(button, index) in navButtons.slice(1, 3)"
        :key="button.__typename"
        :class="{ 'pl-[12px]': index > 0 && button.__typename === 'Button' }"
        class="flex w-6/12 flex-row items-center justify-center border-0 border-transparent"
      >
        <div class="block w-full">
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </div>
      </li>
    </ul>
  </nav>
  <nav
    v-if="stickyHeader && stickyHeader.fields?.links !== undefined"
    class="secondary-navigation fixed z-40 w-full transition-all"
    :class="secondaryNavigationClasses"
  >
    <p
      role="button"
      class="mb-0 block bg-blue-dark px-1 py-1 text-[20px] font-medium capitalize text-white md:hidden md:px-0 md:py-0"
      @click="expandSecondaryNavigation"
    >
      {{ secondaryNavigationTitle
      }}<IconsSvgChevron
        :stroke="'white'"
        :class="{
          '-rotate-90': secondaryNavExpanded,
          'rotate-90': !secondaryNavExpanded,
        }"
        class="ml-1 inline scale-110 transition-all"
      ></IconsSvgChevron>
    </p>
    <nav
      class="origin-top-center absolute w-full bg-blue-lighter transition-all md:relative"
      :class="{
        'max-h-0 overflow-hidden md:max-h-fit': !secondaryNavExpanded,
        'max-h-[500px] overflow-visible': secondaryNavExpanded,
      }"
    >
      <ul
        v-if="stickyHeader.fields?.links"
        class="justify-left flex h-full flex-col pb-1 md:flex-row md:pb-0"
      >
        <li
          v-for="(item, index) in stickyHeader.fields.links"
          :key="index"
          class="first:text-dark-blue flex h-full items-center px-1 pt-1/2 text-blue-dark first:font-bold md:py-1 md:first:bg-blue-dark md:first:text-white [&:not(:first-child)]:font-medium"
          :class="{
            'link-active': isActiveLink(item?.fields?.linkUrl || '/'),
            'py-1/2 [&:not(:first-child)]:hover:text-blue': !isActiveLink(
              item.fields?.linkUrl || '/'
            ),
          }"
        >
          <template v-if="item.sys.contentType.sys.id === 'arrowlink'">
            <span
              v-if="!isActiveLink(item.fields?.linkUrl || '/')"
              class="relative h-full w-fit"
            >
              <LinksArrowLink :arrow-link="item.fields" class="text-[14px]" />
            </span>
            <span v-else class="relative h-full w-fit pb-1/2 md:w-full md:py-0">
              <p
                class="mb-0 px-0 text-[14px] font-medium md:py-0"
                aria-current="page"
              >
                {{ item.fields?.text || 'nav item' }}
              </p>
            </span>
          </template>
        </li>
      </ul>
    </nav>
  </nav>
</template>

<script setup>
  import { useWindowSize } from '@vueuse/core';
  import { NavigationDirection } from '~/types/enums';
  const { analyticsTrack } = useAnalytics();

  const props = defineProps({
    links: {
      type: Array,
      required: true,
    },
    navButtons: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    isTransparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondaryNavigationTitle: {
      type: String,
      required: false,
      default: () => {
        return 'Menu';
      },
    },
    stickyHeader: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
  });
  const scrollDirection = useScrollDirection();
  const { height } = useWindowSize();
  const { fullPath } = useRoute();

  const mobileHeight = ref(null);
  const isMobileActive = ref(false);
  const navigationActive = ref(false);
  const secondaryNavExpanded = ref(false);

  const isActiveLink = computed(() => (link) => link === fullPath);

  const linkStates = reactive(
    Array(props.links.length)
      .fill()
      .map(() => ({ active: false }))
  );

  const buttonProps = (button) => {
    return Object.fromEntries(
      Object.entries(button).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
  };

  const classes = computed(() => {
    return {
      'translate-y-[-52px] md:translate-y-[-75px] bg-white border-color-[#A7B2BE] border-b-1 border-l-0 border-r-0 border-t-0 border-solid':
        scrollDirection.value === NavigationDirection.DOWN,
      'border-color-[#A7B2BE] translate-y-[0px] border-b-1 border-l-0 border-r-0 border-t-0 border-solid md:translate-y-[0px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'bg-white':
        navigationActive.value ||
        scrollDirection.value === NavigationDirection.UP ||
        !props.isTransparent,
      'tn--transparent border-none bg-transparent border-b-1 border-l-0 border-r-0 border-t-0 border-transparent':
        (scrollDirection.value === NavigationDirection.UNSET ||
          scrollDirection.value === NavigationDirection.TOP) &&
        !navigationActive.value &&
        props.isTransparent &&
        scrollDirection.value !== NavigationDirection.UP,
    };
  });

  const secondaryNavigationClasses = computed(() => {
    return {
      'top-[52px] md:top-[75px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.TOP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'top-0': scrollDirection.value === NavigationDirection.DOWN,
    };
  });

  const expandSecondaryNavigation = () => {
    secondaryNavExpanded.value = !secondaryNavExpanded.value;
  };

  const toggleMobileMenu = () => {
    isMobileActive.value = !isMobileActive.value;
    if (isMobileActive.value) {
      addOverlay();
    } else {
      removeOverlay();
    }
  };

  const setActiveMenuItem = (index) => {
    linkStates.forEach((linkState, i) => {
      if (i === index) {
        linkState.active = !linkState.active;
      } else {
        linkState.active = false;
      }
    });
  };

  const removeOverlay = () => {
    if (document.querySelector('.overlay')) {
      document.querySelector('.overlay').remove();
      document.querySelector('html').classList.remove('overflow-y-hidden');
    }
  };

  const addOverlay = () => {
    if (!document.querySelector('.overlay')) {
      const overlay = document.createElement('div');
      overlay.classList.add('overlay');
      document.body.appendChild(overlay);
      document.querySelector('html').classList.add('overflow-y-hidden');
    }
  };

  const trackLink = (text, link) => {
    analyticsTrack('Nav Link Clicked', { link, text });
  };

  watch(height, (newHeight) => {
    mobileHeight.value = newHeight;
  });

  onMounted(() => {
    mobileHeight.value = window.innerHeight;
  });
</script>

<style lang="scss" scoped>
  .tn {
    .tn--nav-buttons {
      :deep(a.btn) {
        @apply min-w-fit text-[10px] font-bold;
      }
      :deep(a.btn.btn--icon.btn--icon-phone) {
        svg {
          path {
            @apply fill-blue-dark;
          }
        }
      }
    }
    &--transparent {
      .tn--nav-buttons {
        :deep(a.btn) {
          @apply text-white;
        }

        :deep(a.btn.btn--icon.btn--icon-phone) {
          svg {
            path {
              @apply fill-white;
            }
          }
        }
      }
    }
    &--nav {
      &-animateY {
        @apply ease-in-out;
        animation-name: slide-in;
        animation-duration: 325ms;
        animation-fill-mode: forwards;
      }

      &-buttons {
        &:deep(a.btn) {
          @apply m-0 min-w-fit border-none bg-transparent p-0 pr-1/2 text-[10px] font-bold text-blue-dark;
        }
      }

      &-mobile {
        .tn--buttons-mobile {
          li {
            :deep(a.btn) {
              @apply min-w-fit text-xxs font-bold;
            }

            &:first-child {
              :deep(a) {
                @apply border-transparent font-bold text-blue;
                @screen md {
                  @apply border-1 border-solid border-blue px-[20px] py-[14px];
                }
              }
            }
            &:nth-child(2) {
              :deep(a.btn) {
                @apply w-full;
              }
            }
            span {
              @apply flex w-full items-center justify-center;
            }
            .btn {
              @apply w-full min-w-max;
            }
            :deep(.tn--nav-login),
            .btn {
              @apply flex w-full items-center justify-center p-0;
            }
          }
        }
      }
    }
    &--header-icon {
      :deep(path) {
        @apply stroke-blue-dark;
      }
      :deep(path.ai--path-arrow) {
        @apply -translate-x-1/4;
      }
      :deep(path.ai--path-body) {
        @apply opacity-0;
      }
    }
    &--link {
      &:active,
      :focus {
        @apply text-blue;
        .tn--link-preview {
          @apply text-gray-dark;
        }
      }
      &-active {
        @apply pointer-events-none border-transparent;
        .tn--link-text,
        .tn--link {
          @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
        }
      }
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
</style>
