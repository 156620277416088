import validate from "/codebuild/output/src2560279378/src/horizon/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirects_45global from "/codebuild/output/src2560279378/src/horizon/middleware/01.redirects.global.js";
import manifest_45route_45rule from "/codebuild/output/src2560279378/src/horizon/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "6s-redirect": () => import("/codebuild/output/src2560279378/src/horizon/middleware/6s-redirect.js")
}