<template>
  <nav
    :class="classes"
    class="tn--nav fixed top-0 z-50 flex h-[52px] w-full flex-row justify-between p-gap2 transition-all delay-100 md:h-[75px]"
  >
    <ul class="flex h-full flex-row items-center justify-between">
      <li class="h-[31px] w-[100px] md:w-[126px]">
        <NuxtLink :to="localePath('/')">
          <LogosSvgLogoMain
            class="tn-logo h-full w-full transition-all"
            :fill="
              !isTransparent ||
              scrollDirection === NavigationDirection.UP ||
              navigationActive
                ? '#00263e'
                : 'white'
            "
          />
        </NuxtLink>
      </li>
    </ul>
    <ul class="tn--nav-menu h-full flex-row items-center justify-between">
      <li
        v-for="(link, index) in links"
        :key="index"
        ref="parentLinks"
        tabindex="0"
        :data-parent="link.text"
        :aria-current="linkStates[index].active ? true : false"
        class="tn--nav-item flex flex-row items-center justify-center hover:cursor-pointer focus-visible:outline-1"
        :class="{
          active: linkStates[index].active,
          selected: activeParentIndex === index,
        }"
      >
        <button
          v-if="
            link.__typename === 'NavLinks' &&
            link.groupsCollection?.items &&
            link.groupsCollection.items.length > 0
          "
          class="flex flex-row items-center justify-center px-1"
          @click="setActiveMenuItem(index)"
          @keydown.enter="setActiveMenuItem(index)"
          @keydown.escape="setActiveMenuItem(null)"
        >
          <p
            v-if="link.text"
            :class="{
              'text-white':
                (scrollDirection === NavigationDirection.TOP ||
                  scrollDirection === NavigationDirection.UNSET) &&
                isTransparent &&
                !navigationActive,
              'text-blue-dark':
                (navigationActive ||
                  scrollDirection === NavigationDirection.UP) &&
                !linkStates[index].active,
              'border-solid border-blue text-blue':
                linkStates[index].active === true,
              'border-transparent': !linkStates[index].active,
            }"
            class="mb-0 box-border flex flex-row items-center justify-center border-b-1 border-l-0 border-r-0 border-t-0 pb-0 font-medium"
          >
            {{ link.text ? link.text : '' }}
          </p>
          <IconsSvgChevron
            class="tn--chevron ml-1/2 transition-all delay-200"
            :stroke="
              isTransparent &&
              !navigationActive &&
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET)
                ? 'white'
                : '#00263e'
            "
            :class="{
              '-rotate-90': linkStates[index].active,
              'rotate-90': !linkStates[index].active,
            }"
          />
        </button>
        <LinksLocaleLink
          v-if="link.__typename === 'NavLink'"
          class="mb-0 px-1"
          :link="link.link"
          :target="link.newWindow ? '_blank' : '_self'"
          @click="trackLink(link.link, link.text)"
        >
          <p
            :class="{
              'text-white':
                (scrollDirection === NavigationDirection.TOP ||
                  scrollDirection === NavigationDirection.UNSET) &&
                isTransparent &&
                !navigationActive,
              'text-blue-dark':
                (navigationActive ||
                  scrollDirection === NavigationDirection.UP) &&
                !linkStates[index].active,
            }"
            class="mb-0 box-border flex flex-row items-center justify-center border-b-1 border-l-0 border-r-0 border-t-0 border-transparent pb-0 font-medium"
          >
            {{ link.text ? link.text : 'Nav Link' }}
          </p></LinksLocaleLink
        >
      </li>
    </ul>
    <ul
      v-if="navButtons.length > 0"
      class="tn--nav-buttons h-full flex-row items-center justify-between"
    >
      <template v-if="navButtons.length === 3">
        <li
          v-for="button in navButtons.slice(0, 1)"
          :key="button.__typename"
          class="px-1"
        >
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </li>
        <li
          v-if="countries.length > 0"
          class="pl-1"
          :class="{
            'border-white':
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET) &&
              isTransparent &&
              !navigationActive,
            'border-b-0 border-l-1 border-r-0 border-t-0 border-solid':
              navButtons.length === 3,
          }"
        >
          <ButtonsTheLocaleSelector
            :transparent="
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET) &&
              isTransparent &&
              !navigationActive
                ? true
                : false
            "
            :countries="countries"
            tabindex="0"
            class="focus-visible:outline-1"
          />
        </li>
        <li
          v-for="button in navButtons.slice(1, 2)"
          :key="button.__typename"
          class="px-1"
        >
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </li>
        <li
          v-for="button in navButtons.slice(2, 3)"
          :key="button.__typename"
          class="px-0"
        >
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </li>
      </template>
      <template v-else-if="navButtons.length === 2">
        <li
          v-if="countries.length > 0"
          class="border-b-0 border-l-0 border-r-1 border-t-0 border-solid pr-1/2"
          :class="{
            'border-white':
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET) &&
              isTransparent &&
              !navigationActive,
          }"
        >
          <ButtonsTheLocaleSelector
            :transparent="
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET) &&
              isTransparent &&
              !navigationActive
                ? true
                : false
            "
            :countries="countries"
            tabindex="0"
            class="focus-visible:outline-1"
          />
        </li>
        <li
          v-for="button in navButtons.slice(0, 1)"
          :key="button.__typename"
          class="px-1/2"
        >
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </li>
        <li
          v-for="button in navButtons.slice(1, 2)"
          :key="button.__typename"
          class="pl-1/2 pr-0"
        >
          <ButtonsTheButton
            v-if="button.__typename === 'Button'"
            :button="buttonProps(button)"
          />
          <TypographyCustomText
            v-else-if="button.__typename === 'CustomText'"
            :custom-text="{ content: button.content, format: button.format }"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </li>
      </template>
    </ul>
  </nav>
  <nav
    v-show="navigationActive"
    ref="navMenuDesktop"
    class="tn--nav-desktop container fixed left-[50%] right-0 z-50 hidden h-auto translate-x-[-50%] bg-white !px-0 md:top-[75px] md:block"
  >
    <IconsSvgCloseIcon
      tabindex="0"
      class="absolute right-[16px] top-[16px] z-30 transition-all hover:scale-110 hover:cursor-pointer"
      stroke="#00263e"
      role="button"
      @click="setActiveMenuItem(null)"
    />
    <ul
      v-for="(parentLink, parentIndex) in links"
      :key="parentIndex"
      class="items-left flex h-full flex-col"
      @click="setActiveMenuItem(parentIndex)"
    >
      <li
        v-show="linkStates[parentIndex].active === true"
        class="ml-0 flex w-full flex-row transition-all"
      >
        <template v-if="parentLink.groupsCollection">
          <ul
            v-for="(groupItem, groupIndex) in parentLink.groupsCollection.items"
            :key="groupItem"
            tabindex="0"
            :class="{
              'w-6/12':
                (groupIndex === 0 && parentIndex === 0) ||
                (groupIndex === 0 && parentIndex === 1) ||
                parentIndex === links.length - 1,
              'w-3/12':
                (groupIndex > 0 && parentIndex === 0) ||
                (groupIndex > 0 && parentIndex === 1),
            }"
            class="cursor-default"
          >
            <li
              :class="{
                'pl-2 pr-1': groupIndex === 0,
                'px-1':
                  groupIndex > 0 &&
                  groupIndex < parentLink.groupsCollection.items.length,
                'pr-2':
                  groupIndex === parentLink.groupsCollection.items.length - 1,
              }"
              class="tn--group relative h-full w-full py-3 transition-all hover:bg-blue-lighter"
            >
              <ul v-if="parentIndex === 0" tabindex="0">
                <li
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  :key="childLink"
                  :class="linkClasses(childLink, childIndex, parentIndex)"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    tabindex="0"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'inline-block text-xs font-bold uppercase':
                        childLink?.type === 'Header',
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    class="tn--link mb-0 block font-medium"
                    @click="trackLink(childLink.link, childLink.previewText)"
                  >
                    <div class="justify-left flex flex-row items-center">
                      <span
                        :class="{
                          'tn--link-header tracking-[1px]':
                            childLink?.type === 'Header',
                          'tn--link-text tracking-normal ':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="w-fit text-blue-dark"
                      >
                        {{ childLink.text }}
                      </span>
                      <IconsExternalLink
                        v-if="
                          childLink?.newWindow && childLink?.type !== 'Header'
                        "
                        class="relative ml-1/4 transition-all delay-200"
                      />
                      <IconsArrowIcon
                        v-else
                        :class="{
                          'tn--header-icon': childLink?.type === 'Header',
                          'tn--link-icon translate-x-[-3px] opacity-0':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="ml-[8px] transition-all delay-200"
                      />
                    </div>
                    <span
                      v-if="childLink.previewText"
                      class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                    >
                      {{ childLink.previewText }}
                    </span></NuxtLink
                  >
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link-text mb-0 block w-max text-xs font-bold uppercase tracking-normal text-blue-dark"
                  >
                    {{ childLink.text }}
                  </p>
                </li>
              </ul>
              <template v-else-if="parentIndex > 0">
                <template v-if="groupIndex === 0">
                  <div
                    v-for="(
                      childLink, childIndex
                    ) in groupItem.linksCollection.items.slice(0, 1)"
                    :key="childLink"
                    :class="{
                      'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                        childLink?.type === 'Header',
                      'w-full': childIndex === 0,
                    }"
                    class="tn--nav-animateY cursor-default opacity-0"
                  >
                    <NuxtLink
                      v-if="childLink.text && childLink.link"
                      :target="childLink?.newWindow ? '_blank' : undefined"
                      :to="localePath(childLink.link)"
                      :class="{
                        'inline-block text-xs font-bold uppercase':
                          childLink?.type === 'Header',
                        'tn--link-active': isActiveLink(childLink.link),
                      }"
                      class="tn--link mb-0 block font-medium"
                      @click="trackLink(childLink.link, childLink.previewText)"
                    >
                      <div class="justify-left flex flex-row items-center">
                        <span
                          :class="{
                            'tn--link-header tracking-[1px]':
                              childLink?.type === 'Header',
                            'tn--link-text w-fit tracking-normal':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="text-blue-dark"
                        >
                          {{ childLink.text }}
                        </span>
                        <IconsExternalLink
                          v-if="
                            childLink?.newWindow && childLink?.type !== 'Header'
                          "
                          class="relative ml-1/4 transition-all delay-200"
                        />
                        <IconsArrowIcon
                          v-else
                          :class="{
                            'tn--header-icon': childLink?.type === 'Header',
                            'tn--link-icon translate-x-[-3px] opacity-0':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="ml-[8px] transition-all delay-200"
                        />
                      </div>
                      <span
                        v-if="childLink.previewText"
                        class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                      >
                        {{ childLink.previewText }}
                      </span></NuxtLink
                    >
                    <p
                      v-else-if="
                        childLink.text &&
                        childLink?.type === 'Header' &&
                        childLink.link === null
                      "
                      class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                    >
                      {{ childLink.text }}
                    </p>
                  </div>
                  <div
                    class="tn--nav-animateY flex flex-row flex-wrap pt-1 opacity-0"
                  >
                    <div
                      v-for="childLink in groupItem.linksCollection.items.slice(
                        1,
                        groupItem.linksCollection.items.length
                      )"
                      :key="childLink"
                      :class="{
                        'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                          childLink?.type === 'Header',
                        'pb-1':
                          childLink?.type === 'Default' ||
                          childLink?.type === null,
                      }"
                      class="w-6/12"
                    >
                      <NuxtLink
                        v-if="childLink.text && childLink.link"
                        :target="childLink?.newWindow ? '_blank' : undefined"
                        :to="localePath(childLink.link)"
                        :class="{
                          'inline-block text-xs font-bold uppercase':
                            childLink?.type === 'Header',
                          'tn--link-active': isActiveLink(childLink.link),
                        }"
                        class="tn--link mb-0 block font-medium"
                        @click="
                          trackLink(childLink.link, childLink.previewText)
                        "
                      >
                        <div class="justify-left flex flex-row items-center">
                          <span
                            :class="{
                              'tn--link-header tracking-[1px]':
                                childLink?.type === 'Header',
                              'tn--link-text w-fit tracking-normal':
                                childLink?.type !== 'Header' ||
                                childLink?.type === null,
                            }"
                            class="text-blue-dark"
                          >
                            {{ childLink.text }}
                          </span>
                          <IconsExternalLink
                            v-if="
                              childLink?.newWindow &&
                              childLink?.type !== 'Header'
                            "
                            class="relative ml-1/4 transition-all delay-200"
                          />
                          <IconsArrowIcon
                            v-else
                            :class="{
                              'tn--header-icon': childLink?.type === 'Header',
                              'tn--link-icon translate-x-[-3px] opacity-0':
                                childLink?.type !== 'Header' ||
                                childLink?.type === null,
                            }"
                            class="ml-[8px] transition-all delay-200"
                          />
                        </div>
                        <span
                          v-if="childLink.previewText"
                          class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                        >
                          {{ childLink.previewText }}
                        </span></NuxtLink
                      >
                      <p
                        v-else-if="
                          childLink.text &&
                          childLink?.type === 'Header' &&
                          childLink.link === null
                        "
                        class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                      >
                        {{ childLink.text }}
                      </p>
                    </div>
                  </div>
                </template>
                <div
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  v-else
                  :key="childLink"
                  :class="{
                    'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                      childLink?.type === 'Header',
                    'pb-1':
                      childLink?.type === 'Default' || childLink?.type === null,
                    'pt-1': childIndex === 1,
                  }"
                  class="tn--nav-animateY cursor-default opacity-0"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'inline-block text-xs font-bold uppercase':
                        childLink?.type === 'Header',
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    :data-testid="
                      childLink.link.includes(ResourceTypes.WEBINARS)
                        ? ResourceTypes.WEBINARS + '-link'
                        : ''
                    "
                    class="tn--link mb-0 block font-medium"
                    @click="trackLink(childLink.link, childLink.previewText)"
                  >
                    <div class="justify-left flex flex-row items-center">
                      <span
                        :class="{
                          'tn--link-header tracking-[1px]':
                            childLink?.type === 'Header',
                          'tn--link-text w-fit tracking-normal':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="text-blue-dark"
                      >
                        {{ childLink.text }}
                      </span>
                      <IconsExternalLink
                        v-if="
                          childLink?.newWindow && childLink?.type !== 'Header'
                        "
                        class="relative ml-1/4 transition-all delay-200"
                      />
                      <IconsArrowIcon
                        v-else
                        :class="{
                          'tn--header-icon': childLink?.type === 'Header',
                          'tn--link-icon translate-x-[-3px] opacity-0':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="ml-[8px] transition-all delay-200"
                      />
                    </div>
                    <span
                      v-if="childLink.previewText"
                      class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                    >
                      {{ childLink.previewText }}
                    </span></NuxtLink
                  >
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                  >
                    {{ childLink.text }}
                  </p>
                </div>
              </template>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </nav>
  <nav
    v-if="stickyHeader && stickyHeader.fields?.links !== undefined"
    class="secondary-navigation fixed z-40 w-full transition-all"
    :class="secondaryNavigationClasses"
  >
    <nav
      class="origin-top-center absolute w-full bg-blue-lighter transition-all md:relative"
    >
      <ul
        v-if="stickyHeader.fields?.links"
        class="justify-left flex h-full flex-col pb-1 md:flex-row md:pb-0"
      >
        <li
          v-for="(item, index) in stickyHeader.fields.links"
          :key="index"
          class="secondary-navigation--links flex h-full items-center px-1 pt-1/2 first:font-bold md:py-1 md:first:bg-blue-dark [&:not(:first-child)]:font-medium"
          :class="{
            'link-active': isActiveLink(item?.fields?.linkUrl || '/'),
            'py-1/2 [&:not(:first-child)]:hover:text-blue': !isActiveLink(
              item.fields?.linkUrl || '/'
            ),
          }"
        >
          <template v-if="item.sys.contentType.sys.id === 'arrowlink'">
            <span
              v-if="!isActiveLink(item.fields?.linkUrl || '/')"
              class="relative h-full w-fit"
            >
              <LinksArrowLink :arrow-link="item.fields" class="text-[14px]" />
            </span>
            <span v-else class="relative h-full w-fit pb-1/2 md:w-full md:py-0">
              <p
                class="mb-0 px-0 text-[14px] font-medium md:py-0"
                aria-current="page"
              >
                {{ item.fields?.text || 'nav item' }}
              </p>
            </span>
          </template>
        </li>
      </ul>
    </nav>
  </nav>
</template>
<script setup>
  import { NavigationDirection, ResourceTypes } from '~/types/enums';

  const localePath = useLocalePath();
  const scrollDirection = useScrollDirection();
  const { fullPath } = useRoute();
  const { analyticsTrack } = useAnalytics();

  const props = defineProps({
    links: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    navButtons: {
      type: Array,
      required: true,
    },
    isTransparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    stickyHeader: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
  });

  const navMenuDesktop = ref(null);
  const navigationActive = ref(false);
  const activeParentIndex = ref(null);

  const isActiveLink = computed(() => (link) => link === fullPath);

  const classes = computed(() => {
    return {
      'translate-y-[-52px] md:translate-y-[-75px] bg-white border-color-[#A7B2BE] border-b-1 border-l-0 border-r-0 border-t-0 border-solid':
        scrollDirection.value === NavigationDirection.DOWN,
      'border-color-[#A7B2BE] translate-y-[0px] border-b-1 border-l-0 border-r-0 border-t-0 border-solid md:translate-y-[0px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'bg-white':
        navigationActive.value ||
        scrollDirection.value === NavigationDirection.UP ||
        !props.isTransparent,
      'tn--transparent border-none bg-transparent border-b-1 border-l-0 border-r-0 border-t-0 border-transparent':
        (scrollDirection.value === NavigationDirection.UNSET ||
          scrollDirection.value === NavigationDirection.TOP) &&
        !navigationActive.value &&
        props.isTransparent &&
        scrollDirection.value !== NavigationDirection.UP,
    };
  });

  const secondaryNavigationClasses = computed(() => {
    return {
      'top-[52px] md:top-[75px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.TOP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'top-0': scrollDirection.value === NavigationDirection.DOWN,
    };
  });

  const linkStates = reactive(
    Array(props.links.length)
      .fill()
      .map(() => ({ active: false }))
  );

  const buttonProps = (button) => {
    return Object.fromEntries(
      Object.entries(button).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
  };

  const linkClasses = (link, index) => {
    const headerClasses =
      link?.type === 'Header'
        ? 'border-solid border-b-1 border-r-0 border-l-0 border-t-0 tn--nav-header pt-0 pb-1'
        : '';
    const defaultClasses =
      link?.type === 'Default' || link?.type === null ? 'pb-1' : '';
    const pt1 = index === 1 ? 'pt-1' : '';

    return `${headerClasses} ${defaultClasses} ${pt1} tn--nav-animateY opacity-0`;
  };

  const removeOverlay = () => {
    if (document.querySelector('.overlay')) {
      document.querySelector('.overlay').remove();
      document.querySelector('html').classList.remove('overflow-y-hidden');
    }
  };

  const addOverlay = () => {
    if (!document.querySelector('.overlay')) {
      const overlay = document.createElement('div');
      overlay.classList.add('overlay');
      document.body.appendChild(overlay);
      document.querySelector('html').classList.add('overflow-y-hidden');
    }
  };

  const removeAnimationClass = () => {
    if (navMenuDesktop.value) {
      navMenuDesktop.value.classList.remove('animated');
    }
  };

  const addAnimationClass = () => {
    if (navMenuDesktop.value) {
      if (!navMenuDesktop.value.classList.contains('animated')) {
        setTimeout(() => {
          navMenuDesktop.value.classList.add('animated');
        }, 200);
      }
    }
  };

  const checkNavigationState = () => {
    for (let i = 0; i < linkStates.length; i++) {
      if (linkStates[i].active) {
        navigationActive.value = true;
        addOverlay();
        addAnimationClass();
        break;
      } else {
        navigationActive.value = false;
        removeOverlay();
      }
    }
  };

  const setActiveParentIndex = () => {
    props.links.forEach((link, index) => {
      if (link.groupsCollection?.items) {
        link.groupsCollection.items.forEach((group) => {
          if (group.linksCollection?.items) {
            group.linksCollection.items.forEach((childLink) => {
              if (childLink.link && isActiveLink.value(childLink.link)) {
                activeParentIndex.value = index;
              }
            });
          }
        });
      }
    });
  };

  const setActiveMenuItem = (index) => {
    linkStates.forEach((linkState, i) => {
      if (i === index) {
        linkState.active = !linkState.active;
      } else {
        linkState.active = false;
      }
    });
    checkNavigationState();
  };

  const handleBodyClick = (event) => {
    if (event.target && event.target.className === 'overlay') {
      setActiveMenuItem(null);
    }
  };

  const trackLink = (text, link) => {
    analyticsTrack('Nav Link Clicked', { link, text });
  };

  watch(navigationActive, (newValue) => {
    if (!newValue || newValue === undefined) {
      removeAnimationClass();
    }
  });

  onBeforeMount(() => {
    setActiveParentIndex();
  });

  onBeforeUnmount(() => {
    removeOverlay();
  });

  onMounted(() => {
    document.body.addEventListener('click', handleBodyClick, true);
  });

  onUnmounted(() => {
    document.body.removeEventListener('click', handleBodyClick);
  });
</script>

<style lang="scss" scoped>
  .secondary-navigation {
    &--links {
      &:first-child {
        &:deep(a) {
          @apply text-white;
        }
      }
    }
  }
  .tn {
    &--transparent {
      :deep(.tn--nav-login) {
        @apply text-white;
      }
      .tn--nav-item {
        &.selected {
          :deep(.tn--chevron) {
            path {
              @apply stroke-white;
            }
          }
          p {
            @apply border-white text-white;
          }
        }
      }
      .tn--nav-buttons {
        li {
          &:first-child {
            :deep(a) {
              @apply text-white;
            }
            :deep(a.btn.btn--icon.btn--icon-phone) {
              @apply flex flex-row-reverse;
              svg {
                path {
                  @apply fill-white;
                }
              }
            }
          }
          &:nth-child(2) {
            @apply border-[#D6DBE1];
          }
        }
      }
    }
    &--nav {
      &-animateY {
        @apply ease-in-out;
        animation-name: slide-in;
        animation-duration: 325ms;
        animation-fill-mode: forwards;
      }
      &-desktop {
        &.animated {
          .tn--nav-animateY {
            animation: none !important;
            opacity: 1 !important;
          }
        }
      }

      &-buttons,
      &-menu,
      &-profile {
        @apply hidden;
      }
      &-buttons,
      &-menu {
        @screen lg-min {
          @apply flex;
        }
      }
      &-buttons {
        :deep(.btn) {
          @apply m-0 px-[20px] py-1/2 text-[11px];
        }
        li {
          &:first-child {
            :deep(a) {
              @apply min-w-fit border-transparent bg-transparent p-0 text-blue-dark;
            }

            :deep(a.btn.btn--icon.btn--icon-phone) {
              @apply flex flex-row-reverse;
              svg {
                @apply mr-1/2;
                path {
                  @apply fill-blue-dark;
                }
              }
              &:hover {
                svg {
                  path {
                    @apply fill-blue;
                  }
                }
              }
            }
          }
        }
      }
      &-profile {
        @screen lg-max {
          @apply block;
        }
      }
      @screen lg-min {
        &:hover {
          @apply bg-white;
          .tn--nav-item {
            &.selected {
              :deep(.tn--chevron) {
                path {
                  @apply stroke-blue;
                }
              }
              p {
                @apply border-blue text-blue;
              }
            }
            a {
              @apply text-blue-dark;
            }
          }
          .tn--nav-buttons {
            li:first-child {
              @apply border-[#D6DBE1];
              :deep(a.btn.btn--icon.btn--icon-phone) {
                svg {
                  path {
                    @apply fill-blue-dark;
                  }
                }
              }
            }
          }
          .tn-logo {
            :deep(g) {
              @apply fill-blue-dark;
            }
          }
          :deep(.tn--nav-login),
          .tn--nav-buttons li:first-child :deep(a) {
            @apply text-blue-dark;
            &:hover {
              @apply text-blue;
            }
          }
          p {
            @apply text-blue-dark;
          }
          .tn--chevron {
            :deep(path) {
              @apply stroke-blue-dark;
            }
          }

          :deep(.stw--the-world-icon) {
            circle,
            ellipse,
            line {
              @apply stroke-blue-dark;
            }
          }
          :deep(.tls--select) {
            @apply text-blue-dark;
          }
        }
      }

      &-item {
        @screen md {
          &:hover {
            p,
            :deep(a) span {
              @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue;
              color: theme('colors.blue.DEFAULT') !important;
            }
            .tn--chevron {
              :deep(path) {
                @apply stroke-blue;
              }
            }
          }
        }
      }
    }
    &--group {
      &:hover {
        .tn--nav-header {
          @apply border-blue-dark;
        }
      }
    }
    &--header-icon {
      :deep(path) {
        @apply stroke-blue-dark;
      }
      :deep(path.ai--path-arrow) {
        @apply -translate-x-1/4;
      }
      :deep(path.ai--path-body) {
        @apply opacity-0;
      }
    }
    &--link {
      &:active,
      :focus {
        @apply text-blue;
        .tn--link-preview {
          @apply text-gray-dark;
        }
      }
      &:hover {
        .tn--link-text,
        .tn--link-header {
          @apply text-blue;
        }
        .tn--link-icon {
          @apply translate-x-0 opacity-100 ease-in-out;
        }
        .tn--header-icon {
          :deep(path.ai--path-arrow) {
            @apply translate-x-0 stroke-blue;
          }
          :deep(path.ai--path-body) {
            @apply stroke-blue opacity-100;
          }
        }
        :deep(.el--external-link-icon) {
          div {
            @apply bg-blue;
          }
          .el--border-left {
            @apply scale-y-0;
          }
          .el--border-bottom {
            @apply scale-x-0;
          }
          .el--arrow,
          .el--arrowbody {
            @apply translate-x-[1px] translate-y-[-1px];
          }
          .el--arrowbody {
            @apply h-full;
          }
        }
      }
      &-active {
        @apply pointer-events-none border-transparent;
        .tn--link-text,
        .tn--link {
          @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
        }
      }
    }
  }

  .tn--nav-item {
    &.active,
    &.selected {
      :deep(.tn--chevron) {
        path {
          @apply stroke-blue;
        }
      }
    }

    &.selected {
      p {
        @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
      }
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
</style>
